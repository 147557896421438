import React, { useLayoutEffect, useState } from "react";
import axios from "axios";

import "./models.css";
import Loader from "../CustomLoader/Loader";

import { withTranslation } from "react-i18next";

const DeseasePrediction = ({ t }) => {
  const [result, setResult] = useState("");
  const [display, setDisplay] = useState(false);
  const [symp1, setSymp1] = useState("");
  const [symp2, setSymp2] = useState("");
  const [symp3, setSymp3] = useState("");
  const [symp4, setSymp4] = useState("");
  const [symp5, setSymp5] = useState("");
  const [loading, setLoading] = useState(false);

  const deseaseData = [
    {
      name: `${t("disease-pre.options.1")}`,
      value: "itching",
    },
    {
      name: `${t("disease-pre.options.2")}`,
      value: "skin_rash",
    },
    {
      name: `${t("disease-pre.options.3")}`,
      value: "continuous_sneezing",
    },
    {
      name: `${t("disease-pre.options.4")}`,
      value: "chills",
    },
    {
      name: `${t("disease-pre.options.5")}`,
      value: "joint_pain",
    },
    {
      name: `${t("disease-pre.options.6")}`,
      value: "stomach_pain",
    },
    {
      name: `${t("disease-pre.options.7")}`,
      value: "acidity",
    },
    {
      name: `${t("disease-pre.options.8")}`,
      value: "vomiting",
    },
    {
      name: `${t("disease-pre.options.9")}`,
      value: "weight_gain",
    },
    {
      name: `${t("disease-pre.options.10")}`,
      value: "anxiety",
    },
    {
      name: `${t("disease-pre.options.11")}`,
      value: "cold_hands_and_feets",
    },
    {
      name: `${t("disease-pre.options.12")}`,
      value: "weight_loss",
    },
    {
      name: `${t("disease-pre.options.13")}`,
      value: "irregular_sugar_level",
    },
    {
      name: `${t("disease-pre.options.14")}`,
      value: "cough",
    },
    {
      name: `${t("disease-pre.options.15")}`,
      value: "high_fever",
    },
    {
      name: `${t("disease-pre.options.16")}`,
      value: "dehydration",
    },
    {
      name: `${t("disease-pre.options.17")}`,
      value: "headache",
    },
    {
      name: `${t("disease-pre.options.18")}`,
      value: "back_pain",
    },
    {
      name: `${t("disease-pre.options.19")}`,
      value: "chest_pain",
    },
    {
      name: `${t("disease-pre.options.20")}`,
      value: "neck_pain",
    },
    {
      name: `${t("disease-pre.options.21")}`,
      value: "dizziness",
    },
    {
      name: `${t("disease-pre.options.22")}`,
      value: "puffy_face_and_eyes",
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!symp1 || !symp2 || !symp3 || !symp4 || !symp5) {
      alert(t("alert"));
    } else {
      setLoading(true);
      setResult("");
      setDisplay(false);

      // console.log(symp1, symp2, symp3, symp4, symp5);
      const data = { symp1, symp2, symp3, symp4, symp5 };

      axios({
        method: "post",
        header: { "Content-Type": "application/json" },
        url: "https://api.lyfespirit.com/dieases_pre",
        data: data,
      })
        .then((res) => {
          console.log(res);
          setResult(res.data.prediction1);
          setLoading(false);
          setDisplay(true);
        })
        .catch((err) => {
          console.log(err);
          setResult(t("errormsg"));
          setLoading(false);
          setDisplay(true);
        });
    }
  };

  return (
    <div className="mt-7 App flex flex-col justify-center items-center p-4">
      <section className="w-full md:w-11/12 md:flex flex-col justify-center items-center pt-3 pb-4 lg:px-4 ">
        <form
          onSubmit={handleSubmit}
          className="rounded font-sans w-full md:w-2/3"
        >
          <h1
            className="w-full px-2 py-4 text-center font-semibold text-2xl text-white font-sans md:text-4xl my-2 shadow-sm"
            style={{ background: "rgba(0, 86, 47, 0.9)" }}
          >
            {t("disease-pre.title")}
          </h1>
          <div className="mb-4 md:justify-between">
            <div
              className="w-full px-2 py-2 my-4 text-white"
              style={{ color: "rgba(0, 86, 47, 0.9)", background: "#fff" }}
            >
              <select
                className="w-full text-center px-3 py-2 text-xl font-medium leading-tight appearance-none focus:outline-none focus:shadow-outline placeholderColor"
                style={{ color: "rgba(0, 86, 47, 0.9)", background: "#fff" }}
                onChange={(e) => setSymp1(e.target.value)}
                value={symp1}
                id="symp1"
                type="text"
                name="symp1"
              >
                <option
                  className="text-center py-2"
                  style={{ color: "#fff", background: "rgba(0, 86, 47, 0.9)" }}
                  value=""
                  disabled
                  defaultValue
                >
                  {t("disease-pre.symp1")}
                </option>
                {deseaseData.map((data, index) => (
                  <option
                    className="text-center py-2"
                    style={{
                      color: "#fff",
                      background: "rgba(0, 86, 47, 0.9)",
                    }}
                    key={index}
                    value={data.value}
                  >
                    {data.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mb-4 md:justify-between">
            <div
              className="w-full px-2 py-2 my-4 text-white"
              style={{ color: "rgba(0, 86, 47, 0.9)", background: "#fff" }}
            >
              {/* <label
                className="block mb-2 text-sm font-bold text-gray-700 bg-transparent"
                htmlFor="symp2"
              >
                Symptom 2
              </label> */}

              <select
                className="w-full text-center px-3 py-2 text-xl font-medium leading-tight appearance-none focus:outline-none focus:shadow-outline placeholderColor"
                style={{ color: "rgba(0, 86, 47, 0.9)", background: "#fff" }}
                onChange={(e) => setSymp2(e.target.value)}
                value={symp2}
                id="symp2"
                type="text"
                name="symp2"
              >
                <option
                  className="text-center py-2"
                  style={{ color: "#fff", background: "rgba(0, 86, 47, 0.9)" }}
                  value=""
                  disabled
                  defaultValue
                >
                  {t("disease-pre.symp2")}
                </option>
                {deseaseData.map((data, index) => (
                  <option
                    className="text-center py-2"
                    style={{
                      color: "#fff",
                      background: "rgba(0, 86, 47, 0.9)",
                    }}
                    key={index}
                    value={data.value}
                  >
                    {data.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="mb-4 md:justify-between">
            <div
              className="w-full px-2 py-2 my-4 text-white"
              style={{ color: "rgba(0, 86, 47, 0.9)", background: "#fff" }}
            >
              {/* <label
                className="block mb-2 text-sm font-bold text-gray-700 bg-transparent"
                htmlFor="symp2"
              >
                Symptom 3
              </label> */}

              <select
                className="w-full text-center px-3 py-2 text-xl font-medium leading-tight appearance-none focus:outline-none focus:shadow-outline placeholderColor"
                style={{ color: "rgba(0, 86, 47, 0.9)", background: "#fff" }}
                onChange={(e) => setSymp3(e.target.value)}
                value={symp3}
                id="symp3"
                type="text"
                name="symp3"
              >
                <option
                  className="text-center py-2"
                  style={{ color: "#fff", background: "rgba(0, 86, 47, 0.9)" }}
                  value=""
                  disabled
                  defaultValue
                >
                  {t("disease-pre.symp3")}
                </option>
                {deseaseData.map((data, index) => (
                  <option
                    className="text-center py-2"
                    style={{
                      color: "#fff",
                      background: "rgba(0, 86, 47, 0.9)",
                    }}
                    key={index}
                    value={data.value}
                  >
                    {data.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mb-4 md:justify-between">
            <div
              className="w-full px-2 py-2 my-4 text-white"
              style={{ color: "rgba(0, 86, 47, 0.9)", background: "#fff" }}
            >
              {/* <label
                className="block mb-2 text-sm font-bold text-gray-700 bg-transparent"
                htmlFor="symp2"
              >
                Symptom 4
              </label> */}

              <select
                className="w-full text-center px-3 py-2 text-xl font-medium leading-tight appearance-none focus:outline-none focus:shadow-outline placeholderColor"
                style={{ color: "rgba(0, 86, 47, 0.9)", background: "#fff" }}
                onChange={(e) => setSymp4(e.target.value)}
                value={symp4}
                id="symp4"
                type="text"
                name="symp4"
              >
                <option
                  className="text-center py-2"
                  style={{ color: "#fff", background: "rgba(0, 86, 47, 0.9)" }}
                  value=""
                  disabled
                  defaultValue
                >
                  {t("disease-pre.symp4")}
                </option>
                {deseaseData.map((data, index) => (
                  <option
                    className="text-center py-2"
                    style={{
                      color: "#fff",
                      background: "rgba(0, 86, 47, 0.9)",
                    }}
                    key={index}
                    value={data.value}
                  >
                    {data.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="mb-4 md:justify-between">
            <div
              className="w-full px-2 py-2 my-4 text-white"
              style={{ color: "rgba(0, 86, 47, 0.9)", background: "#fff" }}
            >
              {/* <label
                className="block mb-2 text-sm font-bold text-gray-700 bg-transparent"
                htmlFor="symp2"
              >
                Symptom 5
              </label> */}

              <select
                className="w-full text-center px-3 py-2 text-xl font-medium leading-tight appearance-none focus:outline-none focus:shadow-outline placeholderColor"
                style={{ color: "rgba(0, 86, 47, 0.9)", background: "#fff" }}
                onChange={(e) => setSymp5(e.target.value)}
                value={symp5}
                id="symp5"
                type="text"
                name="symp5"
              >
                <option
                  className="text-center py-2"
                  style={{ color: "#fff", background: "rgba(0, 86, 47, 0.9)" }}
                  value=""
                  disabled
                  defaultValue
                >
                  {t("disease-pre.symp5")}
                </option>
                {deseaseData.map((data, index) => (
                  <option
                    className="text-center py-2"
                    style={{
                      color: "#fff",
                      background: "rgba(0, 86, 47, 0.9)",
                    }}
                    key={index}
                    value={data.value}
                  >
                    {data.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="mb-2 text-center mt-6 bg-transparent">
            <button
              className=" px-12 py-2 font-bold transition-all duration-700 hover:shadow-xl focus:outline-none focus:shadow-outline"
              type="submit"
              style={{ color: "#fff", background: "rgba(0, 86, 47, 0.9)" }}
            >
              {t("pred")}
            </button>
          </div>
        </form>
      </section>
      <section className="w-full md:w-11/12 md:flex flex-col justify-center items-center pt-3 pb-8 lg:px-4">
        {loading && <Loader />}

        {display && (
          <div
            className="w-full px-2 py-4 text-center font-semibold text-2xl text-white font-sans md:text-4xl my-2 shadow-sm"
            style={{ background: "rgba(0, 86, 47, 0.9)" }}
          >
            {result}
          </div>
        )}
      </section>
    </div>
  );
};

export default withTranslation()(DeseasePrediction);
