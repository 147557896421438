import React, { useState } from "react";
import HomeHeader from "../HomeHeader/HomeHeader";
// import axios from "axios";
import "./models.css";
import Loader from "../CustomLoader/Loader";
import {skinData} from "./modeldata"
import { Link } from "react-router-dom";

const SkinAnalysis = () => {
  const [data, setData] = useState({
    acheExperienced: "",
    allergicReaction: "",
    skinType: "",
    skinPigmentation: "",
    ans1: "",
    ans3: "",
    ans4: "",
    ans5: "",
    ans6: "",
    ans7: "",
  });

  const [display, setDisplay] = useState(false);
  const [teaneed, setTeaneed] = useState("");
  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);

  const onChangeInput = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    setDisplay(false)
   
  };
let resultoftea=[{link:"/product/62108409cf88bf0004dadaf0",name:"Heart Wellness Tea"},{link:"/product/620f404ce0eb1d0004cb8364",name:"Weight Management Tea"}]
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
   // console.log(data);
   // console.log(skinData)
   let tea=skinData.filter(e=> e.acheExperienced===data.acheExperienced && e.allergicReaction===data.allergicReaction && e.skinPigmentation===data.skinPigmentation && e.skinType===data.skinType)
    if(tea.length===0){
      setLoading(false);
     
      setResult(resultoftea);
      setDisplay(true);
    }else{
      setLoading(false);
      setResult(tea[0].tearecommended);
      setDisplay(true);
      console.log(tea[0].tearecommended)
    }
    // setLoading(false);

    // axios({
    //   method: "post",
    //   header: { "Content-Type": "application/json" },
    //   url: "http://ec2-34-227-27-69.compute-1.amazonaws.com:8080/dieases_pre",
    //   data: data,
    // })
    //   .then((res) => {
    //     console.log(res.data);
    //     setResult("Data Recieved");
    //     setLoading(false)
    //     setDisplay(true);
    //   })
    //   .catch((err) =>{
    //     console.log(err)
    //     setResult("Something went wrong");
    //     setLoading(false)
    //     setDisplay(true);
    //   });
  };

  return (
    <>
      <HomeHeader />
      <section
        className="-mt-6 w-full"
        id="form-sec"
        style={{
          background:
            "linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('images/tea-cropped.jpg')",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="pt-28 App flex flex-col justify-center items-center p-4">
          <section className="w-full md:w-11/12 md:flex flex-col justify-center items-center pt-3 pb-4 lg:px-4 ">
            <form
              onSubmit={handleSubmit}
              className="rounded font-sans w-full md:w-2/3"
            >
              <h1
                className="w-full px-2 py-4 text-center font-semibold text-2xl text-white font-sans md:text-4xl my-2 shadow-sm"
                style={{ background: "rgba(0, 86, 47, 0.9)" }}
              >
                Skin Analysis
              </h1>

              <div className="mb-4 mt-3">
                <h1
                  className="w-full px-2 py-4 text-center font-semibold text-lg text-white font-sans md:text-xl mt-2 shadow-sm"
                  style={{ background: "rgba(0, 86, 47, 0.9)" }}
                >
                  In recent times have you ever experienced the Acne on the face
                  ?
                </h1>
                <select
                  className="w-full text-center px-3 py-2 text-xl font-medium leading-tight appearance-none focus:outline-none focus:shadow-outline placeholderColor"
                  style={{ color: "rgba(0, 86, 47, 0.9)", background: "#fff" }}
                  onChange={onChangeInput}
                  value={data.acheExperienced}
                  id="acheExperienced"
                  name="acheExperienced"
                >
                  <option
                    className="text-center bg-white"
                    value=""
                    disabled
                    defaultValue
                  >
                    Select Option
                  </option>
                  <option
                    className="text-center py-2"
                    style={{
                      color: "#fff",
                      background: "rgba(0, 86, 47, 0.9)",
                    }}
                    value="T"
                  >
                    Yes
                  </option>
                  <option
                    className="text-center py-2"
                    style={{
                      color: "#fff",
                      background: "rgba(0, 86, 47, 0.9)",
                    }}
                    value="F"
                  >
                    No
                  </option>
                </select>

                {data.acheExperienced === "T" && (
                  <>
                    <h1
                      className="w-full px-2 py-4 text-center font-semibold text-lg text-white font-sans md:text-xl mt-2 shadow-sm"
                      style={{ background: "rgba(0, 86, 47, 0.9)" }}
                    >
                      Mention the duration of time (No. of Weeks)
                    </h1>
                    <div
                      className="w-full px-2 py-2 mb-4 text-white"
                      style={{
                        color: "rgba(0, 86, 47, 0.9)",
                        background: "#fff",
                      }}
                    >
                      <input
                        className="w-full text-center px-3 py-2 text-xl font-medium leading-tight appearance-none focus:outline-none focus:shadow-outline placeholderColor"
                        style={{
                          color: "rgba(0, 86, 47, 0.9)",
                          background: "#fff",
                        }}
                        autoComplete="off"
                        type="text"
                        name="ans1"
                        required
                        onChange={onChangeInput}
                        value={data.ans1}
                        placeholder="Write Your Answer"
                      />
                    </div>
                  </>
                )}
              </div>

              {(data.ans1 || data.acheExperienced === "F") && (
                <div className="mb-4 mt-3">
                  <h1
                    className="w-full px-2 py-4 text-center font-semibold text-lg text-white font-sans md:text-xl mt-2 shadow-sm"
                    style={{ background: "rgba(0, 86, 47, 0.9)" }}
                  >
                    Is your skin sensitivity or inflamation ?
                  </h1>
                  <select
                    className="w-full text-center px-3 py-2 text-xl font-medium leading-tight appearance-none focus:outline-none focus:shadow-outline placeholderColor"
                    style={{
                      color: "rgba(0, 86, 47, 0.9)",
                      background: "#fff",
                    }}
                    onChange={onChangeInput}
                    value={data.skinType}
                    id="skinType"
                    name="skinType"
                  >
                    <option
                      className="text-center bg-white"
                      value=""
                      disabled
                      defaultValue
                    >
                      Select Option
                    </option>
                    <option
                      className="text-center py-2"
                      style={{
                        color: "#fff",
                        background: "rgba(0, 86, 47, 0.9)",
                      }}
                      value="T"
                    >
                      Sensitive
                    </option>
                    <option
                      className="text-center py-2"
                      style={{
                        color: "#fff",
                        background: "rgba(0, 86, 47, 0.9)",
                      }}
                      value="F"
                    >
                      Inflamation
                    </option>
                  </select>
                </div>
              )}

              {data.skinType && (
                <div className="mb-4 mt-3">
                  <h1
                    className="w-full px-2 py-4 text-center font-semibold text-lg text-white font-sans md:text-xl mt-2 shadow-sm"
                    style={{ background: "rgba(0, 86, 47, 0.9)" }}
                  >
                    Did you ever have an allergic reaction by applying the
                    cosmetics ?
                  </h1>
                  <select
                    className="w-full text-center px-3 py-2 text-xl font-medium leading-tight appearance-none focus:outline-none focus:shadow-outline placeholderColor"
                    style={{
                      color: "rgba(0, 86, 47, 0.9)",
                      background: "#fff",
                    }}
                    onChange={onChangeInput}
                    value={data.allergicReaction}
                    id="allergicReaction"
                    name="allergicReaction"
                  >
                    <option
                      className="text-center bg-white"
                      value=""
                      disabled
                      defaultValue
                    >
                      Select Option
                    </option>
                    <option
                      className="text-center py-2"
                      style={{
                        color: "#fff",
                        background: "rgba(0, 86, 47, 0.9)",
                      }}
                      value="T"
                    >
                      Yes
                    </option>
                    <option
                      className="text-center py-2"
                      style={{
                        color: "#fff",
                        background: "rgba(0, 86, 47, 0.9)",
                      }}
                      value="F"
                    >
                      No
                    </option>
                  </select>
                  {data.allergicReaction === "T" && (
                    <>
                      {" "}
                      <h1
                        className="w-full px-2 py-4 text-center font-semibold text-lg text-white font-sans md:text-xl mt-2 shadow-sm"
                        style={{ background: "rgba(0, 86, 47, 0.9)" }}
                      >
                        Please describe your problem
                      </h1>
                      <div
                        className="w-full px-2 py-2 mb-4 text-white"
                        style={{
                          color: "rgba(0, 86, 47, 0.9)",
                          background: "#fff",
                        }}
                      >
                        <input
                          className="w-full text-center px-3 py-2 text-xl font-medium leading-tight appearance-none focus:outline-none focus:shadow-outline placeholderColor"
                          style={{
                            color: "rgba(0, 86, 47, 0.9)",
                            background: "#fff",
                          }}
                          autoComplete="off"
                          type="text"
                          name="ans3"
                          onChange={onChangeInput}
                          value={data.ans3}
                          placeholder="Write Your Answer"
                        />
                      </div>
                    </>
                  )}
                </div>
              )}

              {(data.ans3 || data.allergicReaction === "F") && (
                <div className="mb-4 mt-3">
                  <h1
                    className="w-full px-2 py-4 text-center font-semibold text-lg text-white font-sans md:text-xl mt-2 shadow-sm"
                    style={{ background: "rgba(0, 86, 47, 0.9)" }}
                  >
                    Did you have any skin pigmentation ?
                  </h1>
                  <select
                    className="w-full text-center px-3 py-2 text-xl font-medium leading-tight appearance-none focus:outline-none focus:shadow-outline placeholderColor"
                    style={{
                      color: "rgba(0, 86, 47, 0.9)",
                      background: "#fff",
                    }}
                    onChange={onChangeInput}
                    value={data.skinPigmentation}
                    id="skinPigmentation"
                    name="skinPigmentation"
                  >
                    <option
                      className="text-center bg-white"
                      value=""
                      disabled
                      defaultValue
                    >
                      Select Option
                    </option>
                    <option
                      className="text-center py-2"
                      style={{
                        color: "#fff",
                        background: "rgba(0, 86, 47, 0.9)",
                      }}
                      value="T"
                    >
                      Yes
                    </option>
                    <option
                      className="text-center py-2"
                      style={{
                        color: "#fff",
                        background: "rgba(0, 86, 47, 0.9)",
                      }}
                      value="F"
                    >
                      No
                    </option>
                  </select>
                  {data.skinPigmentation === "T" && (
                    <>
                      {" "}
                      <h1
                        className="w-full px-2 py-4 text-center font-semibold text-lg text-white font-sans md:text-xl mt-2 shadow-sm"
                        style={{ background: "rgba(0, 86, 47, 0.9)" }}
                      >
                        Please describe your problem .
                      </h1>
                      <div
                        className="w-full px-2 py-2 mb-4 text-white"
                        style={{
                          color: "rgba(0, 86, 47, 0.9)",
                          background: "#fff",
                        }}
                      >
                        <input
                          className="w-full text-center px-3 py-2 text-xl font-medium leading-tight appearance-none focus:outline-none focus:shadow-outline placeholderColor"
                          style={{
                            color: "rgba(0, 86, 47, 0.9)",
                            background: "#fff",
                          }}
                          autoComplete="off"
                          type="text"
                          name="ans4"
                          onChange={onChangeInput}
                          value={data.ans4}
                          placeholder="Write Your Answer"
                        />
                      </div>
                    </>
                  )}
                </div>
              )}

              <div className="mb-2 text-center mt-6 bg-transparent">
                <button
                  className=" px-12 py-2 font-bold transition-all duration-700 hover:shadow-xl focus:outline-none focus:shadow-outline"
                  type="submit"
                  style={{ color: "#fff", background: "rgba(0, 86, 47, 0.9)" }}
                >
                  Predict
                </button>
              </div>
            </form>
          </section>
          <section>
            {loading && <Loader />}

            {display && (
              <div
                className="mb-2 md:mx-40 text-xl md:text-2xl font-semibold rounded-xl shadow-lg text-center mt-4 bg-white py-4 text-black bg-transparent"
                style={{ color: "#0C2D48" }}
              >
               We recommend you to have these tea. Click on it to buy
                {result.map(e=><div style={{background:"yellow",margin:"5px"}}><Link to={e.link}><h1>{e.name}</h1></Link></div>)}
              </div>
            )}
          </section>
        </div>
      </section>
    </>
  );
};

export default SkinAnalysis;
