import React, { useState } from "react";
import BmiIndexForm from "../../component/Models/BmiIndexForm";
import DeseasePrediction from "../../component/Models/DeseasePrediction";

import { withTranslation } from "react-i18next";

const CheckHealth = ({ t }) => {
  const [dp, setDp] = useState(true);
  const [bmi, setBmi] = useState(false);

  return (
    <section
      className="custom-bg pt-32"
      id="form-sec"
      style={{
        background:
          "linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('images/tea-cropped.jpg')",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="py-3 md:py-6 px-4 flex justify-center items-center">
        <div
          className="py-2 px-4 font-medium text-lg md:text-xl shadow-lg mr-4 cursor-pointer"
          style={{ color: "#fff", background: "rgba(0, 86, 47, 0.9)" }}
          onClick={() => {
            setDp(true);
            setBmi(false);
          }}
        >
          {t("disease-pre.title")}
        </div>
        <div
          className="py-2 px-4 font-medium text-lg md:text-xl shadow-lg cursor-pointer"
          style={{ color: "#fff", background: "rgba(0, 86, 47, 0.9)" }}
          onClick={() => {
            setBmi(true);
            setDp(false);
          }}
        >
          {t("body-status.title")}
        </div>
      </div>
      {bmi && <BmiIndexForm />}
      {dp && <DeseasePrediction />}
    </section>
  );
};

export default withTranslation()(CheckHealth);
