export const skinData =[
    {
        acheExperienced: "T",
    allergicReaction: "T",
    skinType: "T",
    skinPigmentation: "T",
    tearecommended:[{link:"/product/620f6f0eb7ef7000043fcec2",name:"Women Wellness Tea"},{link:"/product/621494606cccbe0004906ec8",name:"Gut Health"}]
    },
    {
        acheExperienced: "T",
    allergicReaction: "T",
    skinType: "T",
    skinPigmentation: "F",
    tearecommended:[{link:"/product/62108409cf88bf0004dadaf0",name:"Heart Wellness Tea"},{link:"/product/620f404ce0eb1d0004cb8364",name:"Weight Management Tea"}]
    },
    {
        acheExperienced: "T",
    allergicReaction: "T",
    skinType: "F",
    skinPigmentation: "T",
    tearecommended:[{link:"/product/620f6f0eb7ef7000043fcec2",name:"Women Wellness Tea"},{link:"/product/621494606cccbe0004906ec8",name:"Gut Health"}]
    },
    {
        acheExperienced: "T",
    allergicReaction: "T",
    skinType: "F",
    skinPigmentation: "F",
    tearecommended:[{link:"/product/620f6f0eb7ef7000043fcec2",name:"Women Wellness Tea"},{link:"/product/621494606cccbe0004906ec8",name:"Gut Health"}]
    },
    {
        acheExperienced: "T",
    allergicReaction: "F",
    skinType: "T",
    skinPigmentation: "T",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
    },
    {
        acheExperienced: "T",
    allergicReaction: "F",
    skinType: "T",
    skinPigmentation: "F",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
    }
    ,
    {
        acheExperienced: "T",
    allergicReaction: "F",
    skinType: "F",
    skinPigmentation: "T",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
    }
    ,
    {
        acheExperienced: "T",
    allergicReaction: "F",
    skinType: "F",
    skinPigmentation: "F",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
    },
    {
        acheExperienced: "F",
    allergicReaction: "T",
    skinType: "T",
    skinPigmentation: "T",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
    },
    {
        acheExperienced: "F",
    allergicReaction: "T",
    skinType: "T",
    skinPigmentation: "F",
    tearecommended:[{link:"/product/62108409cf88bf0004dadaf0",name:"Heart Wellness Tea"}]
    },
    {
        acheExperienced: "F",
    allergicReaction: "T",
    skinType: "F",
    skinPigmentation: "T",
    tearecommended:[{link:"/product/62108409cf88bf0004dadaf0",name:"Heart Wellness Tea"}]
    },
    {
        acheExperienced: "F",
    allergicReaction: "T",
    skinType: "F",
    skinPigmentation: "F",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"},{link:"/product/620f4028e0eb1d0004cb8357",name:"Mental Wellness Tea"}]
    },
    {
        acheExperienced: "F",
    allergicReaction: "F",
    skinType: "T",
    skinPigmentation: "T",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"},{link:"/product/620f4028e0eb1d0004cb8357",name:"Mental Wellness Tea"}]
    },
    {
        acheExperienced: "F",
    allergicReaction: "F",
    skinType: "T",
    skinPigmentation: "F",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"},{link:"/product/620f4028e0eb1d0004cb8357",name:"Mental Wellness Tea"}]
    },
    {
        acheExperienced: "F",
    allergicReaction: "F",
    skinType: "F",
    skinPigmentation: "T",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"},{link:"/product/620f4028e0eb1d0004cb8357",name:"Mental Wellness Tea"}]
    },
    {
        acheExperienced: "F",
    allergicReaction: "F",
    skinType: "F",
    skinPigmentation: "F",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"},{link:"/product/620f4028e0eb1d0004cb8357",name:"Mental Wellness Tea"}]
    }

]
export const staminaData =[
    {
       excerciseReg: "T",
    feelTired: "T",
    lackofFocus: "T",
    asthmaDisease: "T",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"},{link:"/product/620f4028e0eb1d0004cb8357",name:"Mental Wellness Tea"}]
    },
    {
       excerciseReg: "T",
    feelTired: "T",
    lackofFocus: "T",
    asthmaDisease: "F",
    tearecommended:[{link:"/product/62108409cf88bf0004dadaf0",name:"Heart Wellness Tea"},{link:"/product/620f404ce0eb1d0004cb8364",name:"Weight Management Tea"}]
    },
    {
       excerciseReg: "T",
    feelTired: "T",
    lackofFocus: "F",
    asthmaDisease: "T",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"},{link:"/product/620f4028e0eb1d0004cb8357",name:"Mental Wellness Tea"}]
    },
    {
       excerciseReg: "T",
    feelTired: "T",
    lackofFocus: "F",
    asthmaDisease: "F",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"},{link:"/product/620f4028e0eb1d0004cb8357",name:"Mental Wellness Tea"}]
    },
    {
       excerciseReg: "T",
    feelTired: "F",
    lackofFocus: "T",
    asthmaDisease: "T",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
    },
    {
       excerciseReg: "T",
    feelTired: "F",
    lackofFocus: "T",
    asthmaDisease: "F",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
    }
    ,
    {
       excerciseReg: "T",
    feelTired: "F",
    lackofFocus: "F",
    asthmaDisease: "T",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
    }
    ,
    {
       excerciseReg: "T",
    feelTired: "F",
    lackofFocus: "F",
    asthmaDisease: "F",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
    },
    {
       excerciseReg: "F",
    feelTired: "T",
    lackofFocus: "T",
    asthmaDisease: "T",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
    },
    {
       excerciseReg: "F",
    feelTired: "T",
    lackofFocus: "T",
    asthmaDisease: "F",
    tearecommended:[{link:"/product/62108409cf88bf0004dadaf0",name:"Heart Wellness Tea"}]
    },
    {
       excerciseReg: "F",
    feelTired: "T",
    lackofFocus: "F",
    asthmaDisease: "T",
    tearecommended:[{link:"/product/62108409cf88bf0004dadaf0",name:"Heart Wellness Tea"}]
    },
    {
       excerciseReg: "F",
    feelTired: "T",
    lackofFocus: "F",
    asthmaDisease: "F",
    tearecommended:[{link:"/product/620f6f0eb7ef7000043fcec2",name:"Women Wellness Tea"},{link:"/product/621494606cccbe0004906ec8",name:"Gut Health"}]
    },
    {
       excerciseReg: "F",
    feelTired: "F",
    lackofFocus: "T",
    asthmaDisease: "T",
    tearecommended:[{link:"/product/620f6f0eb7ef7000043fcec2",name:"Women Wellness Tea"},{link:"/product/621494606cccbe0004906ec8",name:"Gut Health"}]
    },
    {
       excerciseReg: "F",
    feelTired: "F",
    lackofFocus: "T",
    asthmaDisease: "F",
    tearecommended:[{link:"/product/620f6f0eb7ef7000043fcec2",name:"Women Wellness Tea"},{link:"/product/621494606cccbe0004906ec8",name:"Gut Health"}]
    },
    {
       excerciseReg: "F",
    feelTired: "F",
    lackofFocus: "F",
    asthmaDisease: "T",
    tearecommended:[{link:"/product/620f6f0eb7ef7000043fcec2",name:"Women Wellness Tea"},{link:"/product/621494606cccbe0004906ec8",name:"Gut Health"}]
    },
    {
       excerciseReg: "F",
    feelTired: "F",
    lackofFocus: "F",
    asthmaDisease: "F",
    tearecommended:[{link:"/product/620f6f0eb7ef7000043fcec2",name:"Women Wellness Tea"},{link:"/product/621494606cccbe0004906ec8",name:"Gut Health"}]
    }

]
export const gutData =[
    {
       stomachDiscom: "T",
    uniWeight: "T",
    foodCraving: "T",
    migraneExp: "T",
    moodSwing:"T",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"},{link:"/product/620f4028e0eb1d0004cb8357",name:"Mental Wellness Tea"}]
    },
    {
       stomachDiscom: "T",
    uniWeight: "T",
    foodCraving: "T",
    migraneExp: "F",
    moodSwing:"T",
    tearecommended:[{link:"/product/62108409cf88bf0004dadaf0",name:"Heart Wellness Tea"},{link:"/product/620f404ce0eb1d0004cb8364",name:"Weight Management Tea"}]
    },
    {
       stomachDiscom: "T",
    uniWeight: "T",
    foodCraving: "F",
    migraneExp: "T",
    moodSwing:"T",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"},{link:"/product/620f4028e0eb1d0004cb8357",name:"Mental Wellness Tea"}]
    },
    {
       stomachDiscom: "T",
    uniWeight: "T",
    foodCraving: "F",
    migraneExp: "F",
    moodSwing:"T",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"},{link:"/product/620f4028e0eb1d0004cb8357",name:"Mental Wellness Tea"}]
    },
    {
       stomachDiscom: "T",
    uniWeight: "F",
    foodCraving: "T",
    migraneExp: "T",
    moodSwing:"T",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
    },
    {
       stomachDiscom: "T",
    uniWeight: "F",
    foodCraving: "T",
    migraneExp: "F",
    moodSwing:"T",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
    }
    ,
    {
       stomachDiscom: "T",
    uniWeight: "F",
    foodCraving: "F",
    migraneExp: "T",
    moodSwing:"T",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
    }
    ,
    {
       stomachDiscom: "T",
    uniWeight: "F",
    foodCraving: "F",
    migraneExp: "F",
    moodSwing:"T",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
    },
    {
       stomachDiscom: "F",
    uniWeight: "T",
    foodCraving: "T",
    migraneExp: "T",
    moodSwing:"T",
    tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
    },
    {
       stomachDiscom: "F",
    uniWeight: "T",
    foodCraving: "T",
    migraneExp: "F",
    moodSwing:"T",
    tearecommended:[{link:"/product/62108409cf88bf0004dadaf0",name:"Heart Wellness Tea"}]
    },
    {
       stomachDiscom: "F",
    uniWeight: "T",
    foodCraving: "F",
    migraneExp: "T",
    moodSwing:"T",
    tearecommended:[{link:"/product/62108409cf88bf0004dadaf0",name:"Heart Wellness Tea"}]
    },
    {
       stomachDiscom: "F",
    uniWeight: "T",
    foodCraving: "F",
    migraneExp: "F",
    moodSwing:"T",
    tearecommended:[{link:"/product/620f6f0eb7ef7000043fcec2",name:"Women Wellness Tea"},{link:"/product/621494606cccbe0004906ec8",name:"Gut Health"}]
    },
    {
       stomachDiscom: "F",
    uniWeight: "F",
    foodCraving: "T",
    migraneExp: "T",
    moodSwing:"T",
    tearecommended:[{link:"/product/620f6f0eb7ef7000043fcec2",name:"Women Wellness Tea"},{link:"/product/621494606cccbe0004906ec8",name:"Gut Health"}]
    },
    {
       stomachDiscom: "F",
    uniWeight: "F",
    foodCraving: "T",
    migraneExp: "F",
    moodSwing:"T",
    tearecommended:[{link:"/product/620f6f0eb7ef7000043fcec2",name:"Women Wellness Tea"},{link:"/product/621494606cccbe0004906ec8",name:"Gut Health"}]
    },
    {
       stomachDiscom: "F",
    uniWeight: "F",
    foodCraving: "F",
    migraneExp: "T",
    moodSwing:"T",
    tearecommended:[{link:"/product/620f6f0eb7ef7000043fcec2",name:"Women Wellness Tea"},{link:"/product/621494606cccbe0004906ec8",name:"Gut Health"}]
    },
    {
       stomachDiscom: "F",
    uniWeight: "F",
    foodCraving: "F",
    migraneExp: "F",
    moodSwing:"T",
    tearecommended:[{link:"/product/620f6f0eb7ef7000043fcec2",name:"Women Wellness Tea"},{link:"/product/621494606cccbe0004906ec8",name:"Gut Health"}]
    },
    {
        stomachDiscom: "T",
     uniWeight: "T",
     foodCraving: "T",
     migraneExp: "T",
     moodSwing:"F",
     tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"},{link:"/product/620f4028e0eb1d0004cb8357",name:"Mental Wellness Tea"}]
     },
     {
        stomachDiscom: "T",
     uniWeight: "T",
     foodCraving: "T",
     migraneExp: "F",
     moodSwing:"F",
     tearecommended:[{link:"/product/62108409cf88bf0004dadaf0",name:"Heart Wellness Tea"},{link:"/product/620f404ce0eb1d0004cb8364",name:"Weight Management Tea"}]
     },
     {
        stomachDiscom: "T",
     uniWeight: "T",
     foodCraving: "F",
     migraneExp: "T",
     moodSwing:"F",
     tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"},{link:"/product/620f4028e0eb1d0004cb8357",name:"Mental Wellness Tea"}]
     },
     {
        stomachDiscom: "T",
     uniWeight: "T",
     foodCraving: "F",
     migraneExp: "F",
     moodSwing:"F",
     tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"},{link:"/product/620f4028e0eb1d0004cb8357",name:"Mental Wellness Tea"}]
     },
     {
        stomachDiscom: "T",
     uniWeight: "F",
     foodCraving: "T",
     migraneExp: "T",
     moodSwing:"F",
     tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
     },
     {
        stomachDiscom: "T",
     uniWeight: "F",
     foodCraving: "T",
     migraneExp: "F",
     moodSwing:"F",
     tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
     }
     ,
     {
        stomachDiscom: "T",
     uniWeight: "F",
     foodCraving: "F",
     migraneExp: "T",
     moodSwing:"F",
     tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
     }
     ,
     {
        stomachDiscom: "T",
     uniWeight: "F",
     foodCraving: "F",
     migraneExp: "F",
     moodSwing:"F",
     tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
     },
     {
        stomachDiscom: "F",
     uniWeight: "T",
     foodCraving: "T",
     migraneExp: "T",
     moodSwing:"F",
     tearecommended:[{link:"/product/620f3fffe0eb1d0004cb834c",name:"Skin Wellness Tea"}]
     },
     {
        stomachDiscom: "F",
     uniWeight: "T",
     foodCraving: "T",
     migraneExp: "F",
     moodSwing:"F",
     tearecommended:[{link:"/product/62108409cf88bf0004dadaf0",name:"Heart Wellness Tea"}]
     },
     {
        stomachDiscom: "F",
     uniWeight: "T",
     foodCraving: "F",
     migraneExp: "T",
     moodSwing:"F",
     tearecommended:[{link:"/product/62108409cf88bf0004dadaf0",name:"Heart Wellness Tea"}]
     },
     {
        stomachDiscom: "F",
     uniWeight: "T",
     foodCraving: "F",
     migraneExp: "F",
     moodSwing:"F",
     tearecommended:[{link:"/product/620f6f0eb7ef7000043fcec2",name:"Women Wellness Tea"},{link:"/product/621494606cccbe0004906ec8",name:"Gut Health"}]
     },
     {
        stomachDiscom: "F",
     uniWeight: "F",
     foodCraving: "T",
     migraneExp: "T",
     moodSwing:"F",
     tearecommended:[{link:"/product/620f6f0eb7ef7000043fcec2",name:"Women Wellness Tea"},{link:"/product/621494606cccbe0004906ec8",name:"Gut Health"}]
     },
     {
        stomachDiscom: "F",
     uniWeight: "F",
     foodCraving: "T",
     migraneExp: "F",
     moodSwing:"F",
     tearecommended:[{link:"/product/620f6f0eb7ef7000043fcec2",name:"Women Wellness Tea"},{link:"/product/621494606cccbe0004906ec8",name:"Gut Health"}]
     },
     {
        stomachDiscom: "F",
     uniWeight: "F",
     foodCraving: "F",
     migraneExp: "T",
     moodSwing:"F",
     tearecommended:[{link:"/product/620f6f0eb7ef7000043fcec2",name:"Women Wellness Tea"},{link:"/product/621494606cccbe0004906ec8",name:"Gut Health"}]
     },
     {
        stomachDiscom: "F",
     uniWeight: "F",
     foodCraving: "F",
     migraneExp: "F",
     moodSwing:"F",
     tearecommended:[{link:"/product/620f6f0eb7ef7000043fcec2",name:"Women Wellness Tea"},{link:"/product/621494606cccbe0004906ec8",name:"Gut Health"}]
     }

]

export default { skinData,staminaData,gutData };