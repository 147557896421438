import axios from "axios";

import {

  NEW_CARTITEM_REQUEST,
  NEW_CARTITEM_SUCCESS,
  NEW_CARTITEM_FAIL,
  ALL_CARTITEM_REQUEST,
  ALL_CARTITEM_SUCCESS,
  ALL_CARTITEM_FAIL,
  DELETE_CARTITEM_REQUEST,
  DELETE_CARTITEM_SUCCESS,
  DELETE_CARTITEM_FAIL,
  CLEAR_ERRORS,
} from "../constants/cartmConstants";

// NEW CARTITEM
export const addcartmitem = (id, quantity) => async (dispatch) => {
    try {
        const res = await axios.get(`https://teaproducts.herokuapp.com/api/v1/product/${id}`);
      dispatch({ type: NEW_CARTITEM_REQUEST });
      const token=JSON.parse(localStorage.getItem("token"))
      const config = {
        headers: { "Content-Type": "application/json", "Authorization": token },
      };
      const addcartdata={
        product: res.data.product._id,
        name: res.data.product.name,
        price: res.data.product.price,
        image: res.data.product.images[0].url,
        stock: res.data.product.Stock,
        quantity,
      }
  
      const { data } = await axios.patch(`https://teaproducts.herokuapp.com/api/v1/addcart`, addcartdata, config);
  
      dispatch({
        type: NEW_CARTITEM_SUCCESS,
        payload: data.success,
      });
    } catch (error) {
      dispatch({
        type: NEW_CARTITEM_FAIL,
        payload: error.response.data.message,
      });
    }
  };
  
 
  
  // Delete Review of a Product
  export const removecartm = (cartaddid) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_CARTITEM_REQUEST });
      const token=JSON.parse(localStorage.getItem("token"))
      const config = { headers: { "Authorization": token } };
      const { data } = await axios.delete(
        `https://teaproducts.herokuapp.com/api/v1/addcart/${cartaddid}`,config
      );
  
      dispatch({
        type: DELETE_CARTITEM_SUCCESS,
        payload: data.success,
      });
    } catch (error) {
      dispatch({
        type: DELETE_CARTITEM_FAIL,
        payload: error.response.data.message,
      });
    }
  };
  
  // Clearing Errors
  export const clearErrors = () => async (dispatch) => {
    dispatch({ type: CLEAR_ERRORS });
  };