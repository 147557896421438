import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { withTranslation } from "react-i18next";

const TopBar = ({ t }) => {
  const { i18n } = useTranslation();
  const [load, setload] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [navbar, setNavbar] = useState(true);

  useEffect(() => {
    let lang = localStorage.getItem("language");
    if (lang === "ar") {
      setload(true);
    } else {
      setload(false);
    }
  }, []);
  const changeLanguage = (e) => {
    localStorage.setItem("language", e.target.value);
    i18n.changeLanguage(e.target.value);
    setIsOpen(false);
    if (e.target.value === "ar") {
      setload(true);
    } else {
      setload(false);
    }
  };
  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setNavbar(false);
    } else {
      setNavbar(true);
    }
  };
  useEffect(() => {
    changeBackground();
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeBackground);

    // to prevent memory leak
    return () => window.removeEventListener("scroll", changeBackground);
  });

  return (
    <div
      className="w-full h-7 flex justify-start items-center px-1 md:px-6 z-100 shadow-md"
      style={{
        background: `${navbar ? "rgba(0, 0, 0, 0.4)" : "rgb(82, 78, 78)"}`,
      }}
    >
      <div className="relative flex gap-1 md:gap-4">
        {/* <h1>{!load ? "العربية" : "English"}</h1> */}
        {/* <h1>{!load ? "العربية" : "English"}</h1> */}
        {/* <button
          onClick={changeLanguage}
          className={`cursor-pointer p-1 md:py-1 md:px-6 text-sm md:text-base font-semibold uppercase ${
            load ? "text-black bg-white" : "text-white"
          }`}
          value="ar"
        >
          العربية
        </button>
        <button
          onClick={changeLanguage}
          className={`cursor-pointer p-1 md:py-1 md:px-6 text-sm md:text-base font-semibold uppercase ${
            !load ? "text-black bg-white" : "text-white"
          }`}
          value="en"
        >
          English
        </button> */}
        <div
          className="dropdown inline-block relative"
          style={{ zIndex: "999999999" }}
        >
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="bg-gray-300 text-gray-700 font-semibold py-1 px-4 rounded inline-flex items-center"
          >
            <span className="mr-1">{load ? " العربية" : "English"}</span>
            <svg
              className="fill-current h-4 w-4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
            </svg>
          </button>
          <ul
            className={`${
              isOpen ? "block" : "hidden"
            } absolute text-gray-700 pt-1`}
          >
            <li className="bg-gray-200 hover:bg-gray-400" value="en">
              <button
                value="en"
                onClick={changeLanguage}
                className="rounded-t  py-1 px-4 block whitespace-no-wrap cursur-pointer"
              >
                English
              </button>
            </li>
            <li className="bg-gray-200 hover:bg-gray-400" value="ar">
              <button
                value="ar"
                onClick={changeLanguage}
                className=" py-1 px-4 block whitespace-no-wrap cursur-pointer"
              >
                العربية
              </button>
            </li>
          </ul>
        </div>

        {/* <form className="px-1 absolute top-0 right-12 opacity-0 cursor-pointer">
                    <select
                        onChange={changeLanguage}
                        style={{ width: "20px" }}
                        className="w-full h-full text-center outline-none cursor-pointer"
                    >
                        <option className="" value=""></option>
                        <option className="" value="ar">
                            العربية{" "}
                        </option>
                        <option className="" value="en">
                            English
                        </option>
                    </select>
                </form> */}
      </div>
    </div>
  );
};

export default TopBar;
