import React from "react";
import { Helmet } from "react-helmet";
const AskBot = () => {
  return (
    <div>
      <Helmet>
        <script async="true" type="text/javascript">
          {` !function () {
                            if (window.JOONBOT_WIDGET_ID) {
                              console.warn("Joonbot snippet included twice");
                            } else {
                              window.JOONBOT_MODE = "embed";
                              window.JOONBOT_WIDGET_ID = "888d829c-935c-4c94-9f0d-306626a3a9b2";
                              var n, o;
                              o = document.createElement("script");
                              o.src = "https://js.joonbot.com/init.js", o.defer = !0, o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous";
                              n = document.getElementsByTagName("script")[0], n.parentNode.insertBefore(o, n);
                            }
                          }()`}
        </script>
      </Helmet>
      <div className="w-full flex justify-center my-20">
        <div
          id="joonbot-embed"
          style={{
            // backgroundColor: "#F8CF2C",
            backgroundColor: "rgb(247, 247, 240)",
            width: "80vw",
            height: "80vh",
          }}
        ></div>
      </div>
    </div>
  );
};

export default AskBot;
