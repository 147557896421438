import axios from "axios";

import {

  NEW_CARTITEM_REQUEST,
  NEW_CARTITEM_SUCCESS,
  NEW_CARTITEM_FAIL,
  NEW_CARTITEM_RESET,
  DELETE_CARTITEM_RESET,
  DELETE_CARTITEM_REQUEST,
  DELETE_CARTITEM_SUCCESS,
  DELETE_CARTITEM_FAIL,
  CLEAR_ERRORS,
} from "../constants/cartmConstants";

export const newcartmItemReducer = (state = {}, action) => {
    switch (action.type) {
      case NEW_CARTITEM_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case NEW_CARTITEM_SUCCESS:
        return {
          loading: false,
          success: action.payload,
        };
      case NEW_CARTITEM_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case NEW_CARTITEM_RESET:
        return {
          ...state,
          success: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };

  export const removecartmitemReducer = (state = {}, action) => {
    switch (action.type) {
      case DELETE_CARTITEM_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case DELETE_CARTITEM_SUCCESS:
        return {
          ...state,
          loading: false,
          isDeleted: action.payload,
        };
      case DELETE_CARTITEM_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        };
      case DELETE_CARTITEM_RESET:
        return {
          ...state,
          isDeleted: false,
        };
      case CLEAR_ERRORS:
        return {
          ...state,
          error: null,
        };
      default:
        return state;
    }
  };