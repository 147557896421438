import React, { useEffect } from "react";
import "./tea.css";
import { ingredientArr } from "./IngredientData";
import SingleIngredient from "./SingleIngredient";

const TeaDetails = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="tea-details-container">
      <h2 className="tea-details-heading">
        Organic Turmeric Wellness Detox Box - 4 Herbal Teas
      </h2>
      <div className="product-div">
        <img
          className="prod-img"
          src="/shopImages/product1.jpg"
          alt="product123"
        />
        <p className="tea-details-para">
          Upgrade your health regime with four vibrant, sprightly, bountiful
          herbal teas, wrapped in an artisanal wellness box. This wellness pack
          includes: The award-winning Turmeric Spice Herbal Tea, the calming
          Turmeric Ashwagandha Herbal Tea, the nutrient-rich Turmeric Moringa
          Herbal Tea, and the delicious soothing blend of Turmeric Ginger Herbal
          Tea. Rich in beneficial antioxidants and essential nutrients, these
          turmeric teas are great for giving your immunity a boost; reduce
          inflammation and give relief from pain; boost your metabolism and
          improve digestion; alleviate stress and anxiety; soothe symptoms of
          cold and flu; effectively detox body; and improve the functions of
          brain and heart.
        </p>
      </div>
      <div className="steps-container curved">
        <h3 className="sub-heading">Make the Perfect Cup</h3>
        <section className="steps-sub">
          <div className="step-card tea-text-white">
            <img src="/svg/teaDetails1.svg" alt="teastep1" />
            <p className="tea-details-para tea-text-white">
              7Oz / 200ml Water at 80-90°C
            </p>
          </div>
          <div className="step-card tea-text-white">
            <img src="/svg/teaDetails2.svg" alt="teastep2" />
            <p className="tea-details-para tea-text-white">
              Add a Tea Bag and steep 2-3 Mins
            </p>
          </div>
          <div className="step-card tea-text-white">
            <img src="/svg/teaDetails3.svg" alt="teastep3" />
            <p className="tea-details-para tea-text-white">
              Best consumed plain
            </p>
          </div>
        </section>
      </div>
      <section className="ingredient">
        <h3 className="sub-heading">Ingredients</h3>
        <div className="ingredientMain">
          {ingredientArr.map((data) => (
            <SingleIngredient key={data.id} {...data} />
          ))}
        </div>
      </section>
    </div>
  );
};

export default TeaDetails;
