import React from "react";

// backup color #9DE3F6

const SingleIngredient = ({ id, imgUrl, title, desc, hoverImg }) => {
  return (
    <div>
      <div className="ingredientWrapper">
        <div
          className="w-60 shadow-xl border border-yellow-100 p-2 md:p-4 rounded-xl"
          style={{
            background: "#252827",
            color: "#ffffff",
            textAlign: "center",
            height: "84vh",
          }}
        >
          {/*backup card brown color #e7c39c */}
          <div className="bg-transparent">
            <div className="bg-transparent">
              <img
                src={imgUrl}
                alt="cinnamon"
                className="h-80 w-60 rounded bg-transparent"
              />
            </div>
            <h3 className="mt-2  text-lg font-bold bg-transparent">{title}</h3>
            <p className="mt-4  text-light bg-transparent">{desc}</p>
          </div>
        </div>
        {/* Hover Effect */}
        <div className="p-2 h-full w-full transition-all customHover bg-transparent rounded-xl">
          <img
            className="w-full h-full rounded-xl"
            src={hoverImg}
            alt="Cinnamon"
          />
        </div>
      </div>
    </div>
  );
};

export default SingleIngredient;
