export const NEW_CARTITEM_REQUEST = "NEW_CARTITEM_REQUEST";
export const NEW_CARTITEM_SUCCESS = "NEW_CARTITEM_SUCCESS";
export const NEW_CARTITEM_RESET = "NEW_CARTITEM_RESET";
export const NEW_CARTITEM_FAIL = "NEW_CARTITEM_FAIL";

export const ALL_CARTITEM_REQUEST = "ALL_CARTITEM_REQUEST";
export const ALL_CARTITEM_SUCCESS = "ALL_CARTITEM_SUCCESS";
export const ALL_CARTITEM_FAIL = "ALL_CARTITEM_FAIL";

export const DELETE_CARTITEM_REQUEST = "DELETE_CARTITEM_REQUEST";
export const DELETE_CARTITEM_SUCCESS = "DELETE_CARTITEM_SUCCESS";
export const DELETE_CARTITEM_RESET = "DELETE_CARTITEM_RESET";
export const DELETE_CARTITEM_FAIL = "DELETE_CARTITEM_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";