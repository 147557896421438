export const ingredientArr = [
  {
    id: 1,
    imgUrl: "/homeImg/cinnamon.png",
    title: "Cinnamon",
    desc: "Cinnamon Lowers Blood Sugar Levels and Has a Powerful Anti-Diabetic Effect.",
    hoverImg: "/homeImg/ingredientHover/cinnamon.jpeg",
  },
  {
    id: 2,
    imgUrl: "/homeImg/clove.png",
    title: "Clove",
    desc: "Cloves can help reduce your risk of developing heart disease, diabetes, and certain cancers.",
    hoverImg: "/homeImg/ingredientHover/clove.jpeg",
  },
  {
    id: 3,
    imgUrl: "/homeImg/licorice.png",
    title: "Licorice",
    desc: "Licorice can help your digestive system work more effectively.",
    hoverImg: "/homeImg/ingredientHover/licorice.jpeg",
  },
  {
    id: 4,
    imgUrl: "/homeImg/peppermint.png",
    title: "PepperMint",
    desc: "Peppermint calms the muscles of the stomach and improves the flow of bile, which the body uses to digest fats.",
    hoverImg: "/homeImg/ingredientHover/peppermint.jpeg",
  },
  {
    id: 5,
    imgUrl: "/homeImg/turmeric.png",
    title: "Turmeric",
    desc: "Turmeric improve heart health and prevent against Alzheimer's and cancer.",
    hoverImg: "/homeImg/ingredientHover/turmeric.jpeg",
  },
];
