import React, { Fragment, useEffect } from "react";
import "./orderDetails.css";
import { useSelector, useDispatch } from "react-redux";
import MetaData from "../layout/MetaData";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { getOrderDetails, clearErrors } from "../../actions/orderAction";
import Loader from "../layout/Loader/Loader";
import { useAlert } from "react-alert";
import "../TeaDetails/tea.css";
import { ingredientArr } from "../TeaDetails/IngredientData";
import SingleIngredient from "../TeaDetails/SingleIngredient";
import Carousel from "react-material-ui-carousel";
const OrderDetails = ({ match }) => {
  const { order, error, loading } = useSelector((state) => state.orderDetails);

  const dispatch = useDispatch();
  const alert = useAlert();

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    dispatch(getOrderDetails(match.params.id));
  }, [dispatch, alert, error, match.params.id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Order Details" />
          {order.orderItems &&
            order.orderItems.map((item) => (
              <div className="tea-details-container">
                <h2 className="tea-details-heading">{item.name}</h2>

                <div className="product-div">
                  <img className="prod-img" src={item.image} alt="product123" />

                  <p className="tea-details-para">{item.product.description}</p>
                </div>
                <div className="steps-container curved">
                  <h3 className="sub-heading">Make the Perfect Cup</h3>
                  <section className="steps-sub">
                    <div className="step-card tea-text-white">
                      <img src="/svg/teaDetails1.svg" alt="teastep1" />
                      <p className="tea-details-para tea-text-white">
                        7Oz / 200ml Water at 80-90°C
                      </p>
                    </div>
                    <div className="step-card tea-text-white">
                      <img src="/svg/teaDetails2.svg" alt="teastep2" />
                      <p className="tea-details-para tea-text-white">
                        Add a Tea Bag and steep 2-3 Mins
                      </p>
                    </div>
                    <div className="step-card tea-text-white">
                      <img src="/svg/teaDetails3.svg" alt="teastep3" />
                      <p className="tea-details-para tea-text-white">
                        Best consumed plain
                      </p>
                    </div>
                  </section>
                </div>
                <section className="ingredient">
                  <h3 className="sub-heading">Ingredients</h3>
                  <div className="ingredientMain">
                    {ingredientArr.map((data) => (
                      <SingleIngredient key={data.id} {...data} />
                    ))}
                  </div>
                </section>
              </div>
            ))}
          <div className="orderDetailsPage" style={{ marginTop: "100px" }}>
            <div className="orderDetailsContainer">
              <div
                style={{
                  background: "yellow",
                  width: "50%",
                  padding: "10px",
                  borderRadius: "30px",
                  textAlign: "center",
                }}
              >
                <Link to="/orders">All your recent order list</Link>
              </div>
              <Typography component="h1">Last Order detail</Typography>
              <div className="orderDetailsCartItems">
                <Typography>Order Items:</Typography>
                <div className="orderDetailsCartItemsContainer">
                  {order.orderItems &&
                    order.orderItems.map((item) => (
                      <div key={item.product}>
                        <img src={item.image} alt="Product" />
                        <Link to={`/product/${item.product._id}`}>
                          {item.name}
                        </Link>{" "}
                        <span></span>
                        <span>{item.product.description}</span>
                        <span>
                          {item.quantity} X ₹{item.price} ={" "}
                          <b>₹{item.price * item.quantity}</b>
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              <Typography>Shipping Info</Typography>
              <div className="orderDetailsContainerBox">
                <div>
                  <p>Name:</p>
                  <span>{order.user && order.user.name}</span>
                </div>
                <div>
                  <p>Phone:</p>
                  <span>
                    {order.shippingInfo && order.shippingInfo.phoneNo}
                  </span>
                </div>
                <div>
                  <p>Address:</p>
                  <span>
                    {order.shippingInfo &&
                      `${order.shippingInfo.address}, ${order.shippingInfo.city}, ${order.shippingInfo.state}, ${order.shippingInfo.pinCode}, ${order.shippingInfo.country}`}
                  </span>
                </div>
              </div>
              <Typography>Payment</Typography>
              <div className="orderDetailsContainerBox">
                <div>
                  <p
                    className={
                      order.isPaid && order.isPaid ? "greenColor" : "redColor"
                    }
                  >
                    {order.isPaid && order.isPaid ? "PAID" : "NOT PAID"}
                  </p>
                </div>

                <div>
                  <p>Amount:</p>
                  <span>{order.totalPrice && order.totalPrice}</span>
                </div>
              </div>

              <Typography>Order Status</Typography>
              <div className="orderDetailsContainerBox">
                <div>
                  <p
                    className={
                      order.orderStatus && order.orderStatus === "Delivered"
                        ? "greenColor"
                        : "redColor"
                    }
                  >
                    {order.orderStatus && order.orderStatus}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default OrderDetails;
